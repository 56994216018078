import React from "react";
import SecondButton from "./SecondButton";
import { Link } from "react-router-dom";

const MobProjectComponent = (props) => {
    return(
        <>
        <div className='bg-bg-black rounded-3xl hidden xs:flex flex-col pt-4 px-4 pb-5'>
            <span className='font-[Roboto] text-green'>{props.number}</span>
            <img alt='' src={props.img} className='mx-auto'/>
            <h1 className='text-[21px] text-white font-medium text-center mt-5'>{props.title}</h1>
            <h2 className='text-xs text-gray font-[Roboto] text-center mt-[18px] mb-[22px]'>{props.subtitle}</h2>
            <div className='flex justify-center'>
                {props.btn ? <Link to={props.link}><SecondButton text={props.btn} /></Link> : ''}
            </div>
        </div>
        </>
    )
}

export default MobProjectComponent;