import React, {useEffect} from "react";
import Header from "../components/Header";
import HistoryComponent from "../components/HistoryComponent";
import PlanComponent from "../components/PlanComponent";
import Footer from "../components/Footer";
import SecondButton from "../components/SecondButton";
import ProjectComponent from "../components/ProjectComponent";
import MobProjectComponent from "../components/MobProjectComponent";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SupportChat from "../components/SupportChat";

const About = () => {

    const { hash } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if(hash){
            const element = document.getElementById(hash.substring(1));
            if(element){
                element.scrollIntoView({});
            }
        }else{
            window.scrollTo({ top: 0, });
        }
    }, [hash]);

    return(
        <>
            <SupportChat />
            <Header />
            <div id='history' className='bg-bg-black py-40
            lg:py-20
            xs:py-[50px]'>
                <div className='w-2/3 mx-auto max-w-[1300px]
                xxl:w-4/5
                xl:w-11/12'>
                    <h1 className='text-white text-[56px] mb-[70px] font-medium
                    lg:text-5xl lg:mb-[50px] 
                    xs:text-[26px] xs:mb-[30px]'>{t('historyUnity')}</h1>
                    <HistoryComponent 
                        year={`2018-2019 ${t('doubleYear')}`}
                        title={t('history1Title')}
                        subtitle={t('history1Subtitle')}
                    />
                    <hr className='border-hr my-11 lg:my-10 xs:my-[30px]'></hr>
                    <HistoryComponent 
                        year={`2020 ${t('year')}`}
                        title={t('history2Title')}
                        subtitle={t('history2Subtitle')}
                    />
                    <hr className='border-hr my-11 lg:my-10 xs:my-[30px]'></hr>
                    <HistoryComponent 
                        year={`2021 ${t('year')}`}
                        title={t('history3Title')}
                        subtitle={t('history3Subtitle')}
                    />
                    <hr className='border-hr my-11 lg:my-10 xs:my-[30px]'></hr>
                    <HistoryComponent 
                        year={`2022 ${t('year')}`}
                        title={t('history4Title')}
                        subtitle={t('history4Subtitle')}
                    />
                    <hr className='border-hr my-11 lg:my-10 xs:my-[30px]'></hr>
                    <HistoryComponent 
                        year={`2023-2024 ${t('year')}`}
                        title={t('history5Title')}
                        subtitle={t('history5Subtitle')}
                    />
                </div>
            </div>

            <div id='projects' className='bg-dark-green py-40
                lg:py-20
                xs:py-[50px]'>
                <div className='w-2/3 mx-auto max-w-[1300px]
                xxl:w-4/5
                xl:w-11/12'>
                    <h1 className='text-white text-[56px] mb-[70px] font-medium
                    lg:text-5xl lg:mb-[50px]
                    xs:text-[26px] xs:mb-[30px]'>{t('projects')}</h1>
                    <ProjectComponent 
                        title={t('projc1Title')}
                        subtitle={t('projc1Subtitle')}
                        btn={t('btnDetails')}
                        link='/token#about'
                        img='/images/hcard1.png'
                    />
                    <div className='hidden lg:flex flex-col mt-5 gap-y-5 mb-5'>
                        <ProjectComponent 
                            title={t('projc2Title')}
                            subtitle={t('projc2Subtitle')}
                            img='/images/hcard2.png'
                        />
                        <ProjectComponent 
                            title={t('projc3Title')}
                            subtitle={t('projc3Subtitle')}
                            btn={t('btnDetails')}
                            link='/arb#about'
                            img='/images/hcard3.png'
                        />
                    </div>
                    <div className='flex flex-col gap-y-5'>
                        <MobProjectComponent
                            title={t('projc1Title')}
                            subtitle={t('projc1Subtitle')}
                            btn={t('btnDetails')}
                            link='/token#about'
                            img='/images/hcard1.png'
                        />
                        <MobProjectComponent
                            title={t('projc2Title')}
                            subtitle={t('projc2Subtitle')}
                            img='/images/hcard2.png'
                        />
                        <MobProjectComponent
                            title={t('projc3Title')}
                            subtitle={t('projc3Subtitle')}
                            btn={t('btnDetails')}
                            link='/arb#about'
                            img='/images/hcard3.png'
                        />
                        <MobProjectComponent
                            title={t('projc4Title')}
                            subtitle={t('projc4Subtitle')}
                            btn={t('btnDetails')}
                            link='/wallet'
                            img='/images/wallet_join.svg'
                        />
                    </div>
                    <div className='flex gap-x-5 mt-5 mb-5
                    lg:hidden'>
                        <div className='bg-bg-black rounded-3xl w-5/12 pt-12 px-12 pb-20 flex flex-col justify-between'>
                            <span className='font-[Roboto] text-xl text-green'></span>
                            <img alt='' src='/images/hcard2.png' className=''/>
                            <div>
                                <h1 className='text-[38px] text-white font-medium'>{t('projc2Title')}</h1>
                                <h2 className='text-xl text-gray font-[Roboto] mt-[34px]'>{t('projc2Subtitle')}</h2>
                            </div>
                        </div>
                        <div className='bg-bg-black rounded-3xl w-7/12 pt-12 px-12 pb-12'>
                            {/* <span className='font-[Roboto] text-xl text-green'>03</span> */}
                            <img alt='' src='/images/hcard3.png' className='mx-auto pt-10 pb-20'/>
                            <div className='w-4/5'>
                                <h1 className='text-[38px] text-white font-medium'>{t('projc3Title')}</h1>
                                <h2 className='text-xl text-gray font-[Roboto] mt-[34px] mb-[38px]'>{t('projc3Subtitle')}</h2>
                                <Link to='/arb#about'><SecondButton text={t('btnDetails')} /></Link>
                            </div>
                        </div>
                    </div>
                    <ProjectComponent 
                            title={t('projc4Title')}
                            subtitle={t('projc4Subtitle')}
                            btn={t('btnDetails')}
                            link='/wallet'
                            img='/images/wallet_join.svg'
                            width='w-1/3 lg:w-[30%] md:w-2/5 sm:w-1/2'
                        />
                </div>
            </div>

            <div id='ecosystem' className='bg-bg-black py-40
                lg:py-20
                xs:py-[50px]'>
                <div className='w-2/3 mx-auto max-w-[1300px]
                xxl:w-4/5
                xl:w-11/12'>
                    <h1 className='text-white text-[56px] mb-[70px] font-medium
                    lg:text-5xl lg:mb-[50px]
                    xs:text-[26px] xs:mb-[30px]'>{t('ecosystem')}</h1>
                    <p className='w-full font-[Roboto] text-xl text-gray
                        lg:text-base
                        sm:w-full
                        xs:text-sm xs:w-full'>
                            {t('welcomeSystem1')}
                            <br></br><br></br>
                            {t('welcomeSystem2')}
                            <br></br><br></br>
                            {t('welcomeSystem3')}
                    </p>
                    <hr className='border-hr my-11 lg:my-10 xs:my-[30px]'></hr>
                    <h1 className='w-full text-white font-medium text-[38px] leading-none
                        lg:text-[32px]
                        xs:text-[26px] xs:w-full
                        '>{t('security1')}</h1>
                    <p className='w-full font-[Roboto] text-xl text-gray
                        lg:text-base
                        sm:w-full
                        xs:text-sm xs:w-full'>
                            <br></br>
                            {t('security2')}
                            <br></br><br></br>
                            {t('security3')}
                    </p>
                    <hr className='border-hr my-11 lg:my-10 xs:my-[30px]'></hr>
                    <h1 className='w-full text-white font-medium text-[38px] leading-none
                        lg:text-[32px]
                        xs:text-[26px] xs:w-full
                        '>{t('result1')}</h1>
                    <p className='w-full font-[Roboto] text-xl text-gray
                        lg:text-base
                        sm:w-full
                        xs:text-sm xs:w-full'>
                            <br></br>
                            {t('result2')}</p>
                </div>
            </div>

            <div id='plans' className='bg-dark-green'>
                <div className='w-2/3 mx-auto max-w-[1300px] py-40 flex flex-wrap gap-5
                xxl:w-4/5
                xl:w-11/12 
                lg:py-20
                xs:py-[50px] xs:flex-col'>
                    <div className='w-[calc(33.333%-13.333px)] 
                    lg:w-[calc(50%-10px)] 
                    sm:w-full
                    xs:w-full'>
                        <h1 className='text-[56px] text-white leading-none font-medium mt-7
                        lg:text-5xl
                        xs:mt-0 xs:text-[26px]
                        '>{t('companyPlans')}</h1>
                        <h2 className='font-[Roboto] mt-[34px] text-gray w-5/6
                        lg:mt-[26px]
                        xs:text-xs xs:mt-[18px] xs:w-full'
                        >{t('companyPlansDet')}</h2>
                    </div>
                        <PlanComponent 
                            img='/images/plan1.svg'
                            title={t('plan1Title')}
                            subtitle={t('plan1Subtitle')}
                        />
                        <PlanComponent 
                            img='/images/plan2.svg'
                            title={t('plan2Title')}
                            subtitle={t('plan2Subtitle')}
                        />
                        <PlanComponent 
                            img='/images/plan3.svg'
                            title={t('plan3Title')}
                            subtitle={t('plan3Subtitle')}
                        />
                        <PlanComponent 
                            img='/images/plan4.svg'
                            title={t('plan4Title')}
                            subtitle={t('plan4Subtitle')}
                        />
                        <PlanComponent 
                            img='/images/plan5.svg'
                            title={t('plan5Title')}
                            subtitle={t('plan5Subtitle')}
                        />
                    </div>
            </div>
            <Footer />
        </>
    )
}

export default About;