import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Token from "./pages/Token";
import News from "./pages/News";
import NotFound from "./pages/404";
import ARB from "./pages/ARB";
import About from "./pages/About";
import Wallet from "./pages/Wallet";

const App = () => {
  return (
    <>
        <BrowserRouter>
            <Routes>
                <Route key="/" path="/" element={<Main />} exact />
                <Route key="/about" path="/about" element={<About />} exact />
                <Route key="/token" path="/token" element={<Token />} exact />
                <Route key="/wallet" path="/wallet" element={<Wallet />} exact />
                <Route key="/news" path="/news" element={<News />} exact />
                <Route key="/arb" path="/arb" element={<ARB />} exact />
                <Route key="*" path="*" element={<NotFound />} exact />
            </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
