import React from "react";

const PrimaryButton = (props) => {
    return(
        <a href={props.href} className='bg-green text-lg font-semibold py-[18px] px-8 rounded-[60px] hover:bg-light-green
        lg:text-base
        lg:px-6
        lg:py-[14px]' download={props.download ? true : false}>{props.text}</a>
    )
}

export default PrimaryButton;