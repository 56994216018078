import React from "react";

const HistoryComponent = (props) => {
    return (
        <div className='flex xs:flex-col'>
            <div className='w-3/5
            xs:w-full'>
                <span className='text-light-green font-semibold py-2 px-[14px] bg-dark-green rounded-[60px]
                lg:text-xs
                xs:font-normal
                xs:py-1
                xs:px-3'>{props.year}</span>
                <h3 className='text-2xl text-white mt-4 w-1/2 font-[Roboto]
                xxl:w-3/5
                lg:text-[21px]
                lg:w-4/5
                xs:text-lg
                xs:w-full
                xs:mt-[10px]
                xs:mb-[18px]
                xs:leading-tight'>{props.title}</h3>
            </div>
            <p className='w-2/5 text-gray font-[Roboto] leading-snug
            lg:text-sm
            lg:w-1/2
            xs:text-xs
            xs:w-full'>{props.subtitle}</p>
        </div>
    )
}

export default HistoryComponent;