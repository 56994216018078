import React, { useState, useEffect, useRef } from "react";
import { db } from '../firebase';
import {ref, onValue, update, query, orderByKey, limitToLast} from 'firebase/database';
import { v4 as uuid } from 'uuid';

const SupportChat = () => {

    const [activeDialog, setActiveDialog] = useState(false);
    const [sessionID, setSessionID] = useState();
    const [messages, setMessages] = useState();
    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState(false);
    const messagesContainerRef = useRef(null);
    const audioRef = useRef(null);

    const playSound = () => {
        if(audioRef.current){
            audioRef.current.play();
        }
    };

    const openSupportChat = () => {
        setActiveDialog(true);
        let session = sessionStorage.getItem('session_id');
        if(!session){
            session = uuid();
            sessionStorage.setItem('session_id', session);

            let time = Date.now();
        
            update(ref(db, `messages/${session}/${time}`), {
                time: time,
                from: 'admin',
                text: "Вас приветствует Unity Support, чем можем помочь?"
            });
            
            update(ref(db, `users/${session}`), {
                user_unread: false,
                session: session,
                last_message: time
            });
        }
        setSessionID(session);
    }

    const enterMessage = (event) => {
        if(event.key === 'Enter'){
            sendMessage();
        }
    }

    const sendMessage = () => {
        if(message !== ''){
            sendMessageBot(sessionID, message);
        }
    }

    async function sendMessageBot(session_id, message){
        try{
            let time = Date.now();
        
            update(ref(db, `messages/${session_id}/${time}`), {
                time: time,
                from: 'user',
                text: message
            });

            update(ref(db, `users/${session_id}`), {
                user_unread: false,
                admin_unread: true,
                last_message: time
            });

            setMessage('');

            if(messages.length === 1){
                let time = Date.now();
        
                update(ref(db, `messages/${sessionID}/${time}`), {
                    time: time,
                    from: 'admin',
                    text: "На данный момент все операторы заняты, укажите способ связи с вами, мы с радостью ответим на все ваши вопросы."
                });
            }
            
        }catch(error){
            alert('Сообщение не было доставлено');
        }
    }

    const groupMessage = (messages) => {
        const groupedMessages = [];
        let currentGroup = [];

        messages.forEach((message, index) => {
            if(currentGroup.length === 0) {
                currentGroup.push(message[1]);
            }else{
                const previousMessage = currentGroup[0];
                
                const timeDifference = (message[1].time - previousMessage.time) / 1000 / 60;

                if(timeDifference <= 1 && message[1].from === previousMessage.from){
                    currentGroup.push(message[1]);
                }else{
                    groupedMessages.push(currentGroup);
                    currentGroup = [message[1]];
                }
            }

            if(index === messages.length - 1){
                groupedMessages.push(currentGroup);
            }
        });

        setMessages(groupedMessages);
    }

    useEffect(() => {
        let unsubscribe;
    
        if(sessionID){
            const messagesRef = ref(db, `messages/${sessionID}`);
            const last100MessagesQuery = query(messagesRef, orderByKey(), limitToLast(100));

            unsubscribe = onValue(last100MessagesQuery, (snapshot) => {
                const data = snapshot.val();
                if(data){
                    const newMessages = Object.entries(data)
                    groupMessage(newMessages);

                }else{
                    setMessages();
                }
            });
        }
    
        return () => {
            if(unsubscribe){
                unsubscribe();
            }
        };
    }, [sessionID]);

    useEffect(() => {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        if(activeDialog){
            setNotification(false);
        }
    }, [messages, activeDialog]);

    useEffect(() => {
        if(sessionID){
            const userRef = ref(db, `users/${sessionID}`);
            const unsubscribe = onValue(userRef, (snapshot) => {
                let data = snapshot.val();
                if(data.user_unread){
                    playSound();
                }
                if(data.user_unread && !activeDialog){
                    setNotification(true);
                }
            });

            return () => {
                unsubscribe();
            };
        }
    }, [messages]);

    function dateFormatted(timestamp){
        const date = new Date(timestamp);

        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);

        const formattedDateTime = `${hours}:${minutes}`;

        return formattedDateTime;
    }

    return(
        <>
            <audio ref={audioRef} src='/sound/notif.mp3'></audio>
            <div className={`fixed right-0 w-[300px] z-20 top-[30%] mr-10 ${activeDialog ? '' : 'hidden'}`}>
                <div className='absolute right-0 top-[-10px] bg-green p-1 rounded-full -mr-2 cursor-pointer' onClick={() => setActiveDialog(false)}>
                    <img alt='' src="/images/close.svg" width={15}/>
                </div>
                <div className='flex p-4 bg-dark-green rounded-t-xl'>
                    <img alt='' src='/images/supportAva.svg' width={50} className="bg-green rounded-full p-1"/>
                    <div className='flex flex-col ml-4 justify-center'>
                        <span className='font-bold text-green'>Unity</span>
                        <span className='text-white'>Online Support</span>
                    </div>
                </div>
                <div className='bg-white h-[40vh] p-4 overflow-y-auto custom-scrollbar' ref={messagesContainerRef}>
                {messages?.map((group, index) => {
                    return(
                        <div className='mt-2' key={index}>
                            {group.map((message, index) => {
                                if(message.from === 'user') {
                                    return(
                                        <div key={index} className='flex flex-col items-end mt-1 first:mt-0'>
                                            <p className='px-2 py-1 bg-light-gray rounded-xl text-sm max-w-[90%] ml-8 break-words'>{message.text}</p>
                                        </div>
                                    );
                                }else if(message.from === 'admin') {
                                    return(
                                        <div key={index} className='flex flex-col items-start mt-1 first:mt-0'>
                                            <p className='px-2 py-1 bg-green rounded-xl text-sm max-w-[90%] mr-8 break-words'>{message.text}</p>
                                        </div>
                                    );
                                }else{
                                    return null;
                                }
                            })}
                            {
                                group[0].from === 'user' ?
                                <div className='flex flex-col items-end'>
                                    <span className='text-xs text-gray mr-1'>{dateFormatted(group[0].time)}</span>
                                </div>
                                :
                                <div className='flex flex-col items-start'>
                                    <span className='text-xs text-gray ml-1'>{dateFormatted(group[0].time)}</span>
                                </div>
                            }
                        </div>
                    )
                })}
                </div>
                <div className='border-t border-gray p-4 bg-white rounded-b-xl'>
                    <div className='flex items-center px-2 border border-gray rounded-xl'>
                        <input placeholder="Text..." className="w-full py-1 outline-none" value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={enterMessage}/>
                        <img alt='' src='/images/send-message.svg' width={20} className="ml-2 cursor-pointer" onClick={sendMessage}/>
                    </div>
                </div>
            </div>
            <div className={`fixed right-0 bottom-0 z-20 bg-green p-2 rounded-full mr-10 mb-10 cursor-pointer sm:mr-5 sm:mb-5 ${!activeDialog ? '' : 'hidden'}`} onClick={openSupportChat}>
                <img alt='' src='/images/support.svg' width={40}/>
                {notification === true?
                    <div className='font-bold absolute top-[-4px] left-[-4px] rounded-full bg-[#FF4523] w-[25px] h-[25px] text-center'>
                        <span className=''>1</span>
                    </div>
                    : <></>
                }
            </div>
        </>
    )
}

export default SupportChat;