import React, {useEffect, useState} from "react";
import logo from "../images/logo.svg";
import arrowDown from "../images/arrow_header_down.svg";
import lang from "../images/lang.svg";
import arrowRight from "../images/arrow_black_right.svg";
import burger from "../images/burger_menu.svg";
import cross from "../images/cross.svg";
import menuDown from "../images/header_white_down.svg";
import menuRight from "../images/header_white_right.svg";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Header = () => {

    const [isScrolled, setIsScrolled] = useState(false);
    const [burgerActive, setBurgerActive] = useState(false);
    const [activeMenu, setActiveMenu] = useState(0);

    const { t, i18n } = useTranslation();

    const changeLang = () => {
        if(i18n.language === 'en'){
            i18n.changeLanguage('ru');
        }else{
            i18n.changeLanguage('en');
        }
    }

    useEffect(() => {
        const handleScroll = () => {
        const scrollTop = window.scrollY;
            if(scrollTop > 0){
                setIsScrolled(true);
            }else{
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleActiveMenu = (id) => {
        if(activeMenu === id){
            setActiveMenu(0);
        }else{
            setActiveMenu(id);
        }
    }

    const handleLink = () => {
        setActiveMenu(0);
        setBurgerActive(false);
    }

    return(
        <>
        <div className={`w-full fixed z-10 ${isScrolled ? 'bg-bg-black' : ''}`}>
            <div className='mx-auto py-6 flex items-center text-white text-lg font-medium justify-between w-2/3 max-w-[1300px]
            xxl:w-4/5
            xl:w-11/12
            lg:text-base
            xs:py-4'>
                <div className='flex'>
                    <Link to='/'><img src={logo} alt='' className='mr-15 sm:w-[81px]'/></Link>
                    <div className='flex gap-x-12 items-center md:hidden'>
                        <div className='headlink'>
                            <Link to='/about' className='flex items-center hover:text-green'>{t('aboutCompany')}<img src={arrowDown} alt='' className='ml-1' width={10}/></Link>
                            <div className='absolute pt-5 -ml-4 headlink-sub'>
                                <div className='bg-green flex flex-col text-black text-base font-normal font-[Roboto] px-4 py-3 rounded-xl w-[190px]'>
                                    <Link to="/about#history" className='flex link'><img className='link-img' alt='' src={arrowRight}/>{t('historyUnity')}</Link>
                                    <Link to="/about#projects" className='flex link'><img className='link-img' alt='' src={arrowRight}/>{t('projects')}</Link>
                                    <Link to="/about#ecosystem" className='flex link'><img className='link-img' alt='' src={arrowRight}/>{t('ecosystem')}</Link>
                                    <Link to="/about#plans" className='flex link'><img className='link-img' alt='' src={arrowRight}/>{t('plans')}</Link>
                                </div>
                            </div>
                        </div>
                        <div className='headlink'>
                            <Link to='/arb' className='flex items-center hover:text-green'>Com-Unity ARB <img alt='' src={arrowDown} className='ml-1' width={10}/></Link>
                            <div className='absolute pt-5 -ml-4 headlink-sub'>
                                <div className='bg-green flex flex-col text-black text-base font-normal font-[Roboto] px-4 py-3 rounded-xl w-[190px]'>
                                    <Link to="/arb#about" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('aboutApp')}</Link>
                                    <Link to="/arb#arbitrage" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('arbitrage')}</Link>
                                    <Link to="/arb#mission" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('mission')}</Link>
                                    {/* <Link to="/arb#technology" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('technology')}</Link> */}
                                    <Link to="/arb#invite" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('invite')}</Link>
                                </div>
                            </div>
                        </div>
                        <div className='headlink'>
                            <Link to='/token' className='flex items-center hover:text-green'>{t('token')} <img alt='' src={arrowDown} className='ml-1' width={10}/></Link>
                            <div className='absolute pt-5 -ml-4 headlink-sub'>
                                <div className='bg-green flex flex-col text-black text-base font-normal font-[Roboto] px-4 py-3 rounded-xl w-[190px]'>
                                    <Link to="/token#about" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('aboutToken')}</Link>
                                    <Link to="/token#buy" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('btnBuy')}</Link>
                                    <Link to="/token#etherscan" className='flex link'><img alt='' className='link-img' src={arrowRight}/>EtherScan</Link>
                                </div>
                            </div>
                        </div>
                        <div className='headlink'>
                            <Link to='/wallet' className='flex items-center hover:text-green'>{t('wallet')}<img alt='' src={arrowDown} className='ml-1' width={10}/></Link>
                            <div className='absolute pt-5 -ml-4 headlink-sub'>
                                <div className='bg-green flex flex-col text-black text-base font-normal font-[Roboto] px-4 py-3 rounded-xl w-[190px]'>
                                    <Link to="/wallet#about" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('aboutWallet')}</Link>
                                    <Link to="/wallet#advantages" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('advantagesWallet')}</Link>
                                    <Link to="/wallet#download" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('downloadWallet')}</Link>
                                </div>
                            </div>
                        </div>
                        <div className='headlink'>
                            <Link to='/news' className='flex items-center hover:text-green'>{t('news')} <img alt='' src={arrowDown} className='ml-1' width={10}/></Link>
                            <div className='absolute pt-5 -ml-4 headlink-sub'>
                                <div className='bg-green flex flex-col text-black text-base font-normal font-[Roboto] px-4 py-3 rounded-xl w-[190px]'>
                                    <Link to="/news#development" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('development')}</Link>
                                    <Link to="/news#partners" className='flex link'><img alt='' className='link-img' src={arrowRight}/>{t('partners')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex'>
                    <div className='flex cursor-pointer' onClick={changeLang}>
                        <img alt='' src={lang} width={24}></img>
                        <span className='flex items-center ml-2
                        lg:hidden'>{i18n.language === 'en' ? 'English' : 'Русский'}</span>
                    </div>
                    {/* <a className='flex items-center 
                    lg:ml-2 md:hidden'>{t('support')}</a> */}
                    <img alt='' src={burger} width={24} className='ml-6 hidden md:flex' onClick={() => setBurgerActive(true)}/>
                </div>
            </div>
        </div>
        {burgerActive ? 
        <div className='fixed w-full bg-bg-black min-h-[100vh] z-10 px-5 py-4'>
            <div className='flex justify-between mb-4'>
                <Link to='/'><img alt='' src={logo} width={81} className=''/></Link>
                <img alt='' src={cross} onClick={() => setBurgerActive(false)}/>
            </div>
            <div className='flex justify-between py-4'>
                <Link to='/about' className='font-[21px] text-white' onClick={handleLink}>{t('aboutCompany')}</Link>
                <img alt='' src={menuDown} width={20} className={`p-[2px] transition-transform ${activeMenu === 1 ? 'rotate-180' : ''}`} onClick={() => handleActiveMenu(1)}/>
            </div> 
            <div className={`flex flex-col gap-y-2 pb-5 ${activeMenu === 1 ? '' : 'hidden'}`} onClick={handleLink}>
                <Link to="/about#history" className='text-sm text-gray'>{t('historyUnity')}</Link>
                <Link to="/about#projects" className='text-sm text-gray'>{t('projects')}</Link>
                <Link to="/about#ecosystem" className='text-sm text-gray'>{t('ecosystem')}</Link>
                <Link to="/about#plans" className='text-sm text-gray'>{t('plans')}</Link>
            </div>
            <hr className='border-hr'></hr>
            <div className='flex justify-between py-4'>
                <Link to='/arb' className='font-[21px] text-white' onClick={handleLink}>Com-Unity ARB</Link>
                <img alt='' src={menuDown} width={20} className={`p-[2px] transition-transform ${activeMenu === 2 ? 'rotate-180' : ''}`} onClick={() => handleActiveMenu(2)}/>
            </div>
            <div className={`flex flex-col gap-y-2 pb-5 ${activeMenu === 2 ? '' : 'hidden'}`} onClick={handleLink}>
                <Link to="/arb#about" className='text-sm text-gray'>{t('aboutApp')}</Link>
                <Link to="/arb#arbitrage" className='text-sm text-gray'>{t('arbitrage')}</Link>
                <Link to="/arb#mission" className='text-sm text-gray'>{t('mission')}</Link>
                {/* <Link to="/arb#technology" className='text-sm text-gray'>{t('technology')}</Link> */}
                <Link to="/arb#invite" className='text-sm text-gray'>{t('invite')}</Link>
            </div>
            <hr className='border-hr'></hr>
            <div className='flex justify-between py-4' id={3}>
                <Link to='/token' className='font-[21px] text-white' onClick={handleLink}>{t('token')}</Link>
                <img alt='' src={menuDown} width={20} className={`p-[2px] transition-transform ${activeMenu === 3 ? 'rotate-180' : ''}`} onClick={() => handleActiveMenu(3)}/>
            </div>
            <div className={`flex flex-col gap-y-2 pb-5 ${activeMenu === 3 ? '' : 'hidden'}`} onClick={handleLink}>
                <Link to="/token#about" className='text-sm text-gray'>{t('aboutToken')}</Link>
                <Link to="/token#buy" className='text-sm text-gray'>{t('btnBuy')}</Link>
                <Link to="/token#etherscan" className='text-sm text-gray'>EtherScan</Link>
            </div>
            <hr className='border-hr'></hr>
            <div className='flex justify-between py-4' id={4}>
                <Link to='/wallet' className='font-[21px] text-white' onClick={handleLink}>{t('wallet')}</Link>
                <img alt='' src={menuDown} width={20} className={`p-[2px] transition-transform ${activeMenu === 4 ? 'rotate-180' : ''}`} onClick={() => handleActiveMenu(4)}/>
            </div>
            <div className={`flex flex-col gap-y-2 pb-5 ${activeMenu === 4 ? '' : 'hidden'}`} onClick={handleLink}>
                <Link to="/wallet#about" className='text-sm text-gray'>{t('aboutWallet')}</Link>
                <Link to="/wallet#advantages" className='text-sm text-gray'>{t('advantagesWallet')}</Link>
                <Link to="/wallet#download" className='text-sm text-gray'>{t('downloadWallet')}</Link>
            </div>
            <hr className='border-hr'></hr>
            <div className='flex justify-between py-4' id={5}>
                <Link to='/news' className='font-[21px] text-white' onClick={handleLink}>{t('news')}</Link>
                <img alt='' src={menuDown} width={20} className={`p-[2px] transition-transform ${activeMenu === 5 ? 'rotate-180' : ''}`} onClick={() => handleActiveMenu(5)}/>
            </div>
            <div className={`flex flex-col gap-y-2 pb-5 ${activeMenu === 5 ? '' : 'hidden'}`} onClick={handleLink}>
                <Link to="/news#development" className='text-sm text-gray'>{t('development')}</Link>
                <Link to="/news#partners" className='text-sm text-gray'>{t('partners')}</Link>
            </div>
            <hr className='border-hr'></hr>
            {/* <img alt='' src={menuRight} /> */}
        </div>
        : ''}
        </>
    )
}

export default Header;