import React from "react";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { t } = useTranslation();

    return(
        <div className='bg-bg-black'>
            <div className='w-2/3 max-w-[1300px] py-[100px] mx-auto flex items-start justify-between
            xxl:w-4/5
            xl:w-11/12 lg:hidden'>
                <Link to='/'><img src={logo} alt=''/></Link>
                <div className='flex flex-col'>
                    <Link to='/about' className='text-white text-lg font-medium hover:text-green'>{t('aboutCompany')}</Link>
                    <Link to="/about#history" className='font-[Roboto] text-gray mt-5 hover:text-green'>{t('historyUnity')}</Link>
                    <Link to="/about#projects" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('projects')}</Link>
                    <Link to="/about#ecosystem" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('ecosystem')}</Link>
                    <Link to="/about#plans" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('plans')}</Link>
                </div>
                <div className='flex flex-col'>
                    <Link to='/arb' className='text-white text-lg font-medium hover:text-green'>Com-Unity ARB</Link>
                    <Link to="/arb#about" className='font-[Roboto] text-gray mt-5 hover:text-green'>{t('aboutApp')}</Link>
                    <Link to="/arb#arbitrage" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('arbitrage')}</Link>
                    <Link to="/arb#mission" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('mission')}</Link>
                    {/* <Link to="/arb#technology" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('technology')}</Link> */}
                    <Link to="/arb#invite" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('invite')}</Link>
                </div>
                <div className='flex flex-col'>
                    <Link to='/token' className='text-white text-lg font-medium hover:text-green'>{t('token')}</Link>
                    <Link to="/token#about" className='font-[Roboto] text-gray mt-5 hover:text-green'>{t('aboutToken')}</Link>
                    <Link to="/token#buy" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('btnBuy')}</Link>
                    <Link to="/token#etherscan" className='font-[Roboto] text-gray mt-3 hover:text-green'>EtherScan</Link>
                </div>
                <div className='flex flex-col'>
                    <Link to='/wallet' className='text-white text-lg font-medium hover:text-green'>{t('wallet')}</Link>
                    <Link to="/wallet#about" className='font-[Roboto] text-gray mt-5 hover:text-green'>{t('aboutWallet')}</Link>
                    <Link to="/wallet#advantages" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('advantagesWallet')}</Link>
                    <Link to="/wallet#download" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('downloadWallet')}</Link>
                </div>
                <div className='flex flex-col'>
                    <Link to='/news' className='text-white text-lg font-medium hover:text-green'>{t('news')}</Link>
                    <Link to="/news#development" className='font-[Roboto] text-gray mt-5 hover:text-green'>{t('development')}</Link>
                    <Link to="/news#partners" className='font-[Roboto] text-gray mt-3 hover:text-green'>{t('partners')}</Link>
                    {/* <a className='text-white font-medium mt-9 hover:text-green'>{t('support')}</a> */}
                </div>
                {/* <div className='flex flex-col'>
                    <a className='font-[Roboto] text-gray text-xs hover:text-green'>Политика конфиденциальности</a>
                    <a className='font-[Roboto] text-gray text-xs mt-3 hover:text-green'>Условия использования</a>
                </div> */}
            </div>
            <div className='w-11/12 mx-auto hidden items-start justify-between py-[50px] flex-col
            lg:flex'>
                <Link to='/'><img src={logo} alt='' className='xs:w-[81px]'/></Link>
                <div className='flex justify-between w-full mt-[30px] sm:grid sm:grid-cols-2 sm:gap-x-5 sm:gap-y-[34px] sm:mt-5'>
                    <div className='flex flex-col'>
                        <Link to='/about' className='text-white text-lg font-medium hover:text-green xs:text-base'>{t('aboutCompany')}</Link>
                        <Link to="/about#history" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('historyUnity')}</Link>
                        <Link to="/about#projects" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('projects')}</Link>
                        <Link to="/about#ecosystem" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('ecosystem')}</Link>
                        <Link to="/about#plans" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('plans')}</Link>
                    </div>
                    <div className='flex flex-col'>
                        <Link to='/arb' className='text-white text-lg font-medium hover:text-green xs:text-base'>Com-Unity ARB</Link>
                        <Link to="/arb#about" className='font-[Roboto] text-gray mt-4 hover:text-green xs:text-sm'>{t('aboutApp')}</Link>
                        <Link to="/arb#arbitrage" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('arbitrage')}</Link>
                        <Link to="/arb#mission" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('mission')}</Link>
                        {/* <Link to="/arb#technology" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('technology')}</Link> */}
                        <Link to="/arb#invite" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('invite')}</Link>
                    </div>
                    <div className='flex flex-col'>
                        <Link to='/token' className='text-white text-lg font-medium hover:text-green xs:text-base'>{t('token')}</Link>
                        <Link to="/token#about" className='font-[Roboto] text-gray mt-4 hover:text-green xs:text-sm'>{t('aboutToken')}</Link>
                        <Link to="/token#buy" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('btnBuy')}</Link>
                        <Link to="/token#etherscan" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>EtherScan</Link>
                    </div>
                    <div className='flex flex-col'>
                        <Link to='/wallet' className='text-white text-lg font-medium hover:text-green xs:text-base'>{t('wallet')}</Link>
                        <Link to="/wallet#about" className='font-[Roboto] text-gray mt-4 hover:text-green xs:text-sm'>{t('aboutWallet')}</Link>
                        <Link to="/wallet#advantages" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('advantagesWallet')}</Link>
                        <Link to="/wallet#download" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('downloadWallet')}</Link>
                    </div>
                    <div className='flex flex-col'>
                        <Link to='/news' className='text-white text-lg font-medium hover:text-green xs:text-base'>{t('news')}</Link>
                        <Link to="/news#development" className='font-[Roboto] text-gray mt-4 hover:text-green xs:text-sm'>{t('development')}</Link>
                        <Link to="/news#partners" className='font-[Roboto] text-gray mt-[6px] hover:text-green xs:text-sm'>{t('partners')}</Link>
                        {/* <a className='text-white font-medium mt-8 hover:text-green xs:text-base'>{t('support')}</a> */}
                    </div>
                </div>
                {/* <hr className='border-hr my-[30px] w-full xs:my-5'></hr>
                <div className='flex flex-col'>
                    <a className='font-[Roboto] text-gray text-xs hover:text-green xs:text-[10px]'>Политика конфиденциальности</a>
                    <a className='font-[Roboto] text-gray text-xs mt-[6px] hover:text-green xs:text-[10px]'>Условия использования</a>
                </div> */}
            </div>
        </div>
    )
}

export default Footer;