import React from "react";

const PlanComponent = (props) => {
    return(
        <div className='p-7 rounded-[20px] border border-hr w-[calc(33.333%-13.333px)] lg:w-[calc(50%-10px)]
        sm:w-full
        xs:w-full
        xs:border-none
        xs:p-0'>
            <img alt='' src={props.img} className='xs:w-[80px]'/>
            <div className='mt-25 
            sm:mt-10
            xs:mt-[26px]'>
                <h1 className='text-2xl font-[Roboto] text-white xs:text-lg'>{props.title}</h1>
                <h2 className='mt-6 font-[Roboto] text-gray xs:text-xs xs:mt-[18px]'>{props.subtitle}</h2>
            </div>
        </div>
    )
}

export default PlanComponent;