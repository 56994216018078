import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDdbyZmLpHe4aAgREPEHo9KAvRNSRsDr8Q",
  authDomain: "support-base-ce897.firebaseapp.com",
  databaseURL: "https://support-base-ce897-default-rtdb.firebaseio.com",
  projectId: "support-base-ce897",
  storageBucket: "support-base-ce897.appspot.com",
  messagingSenderId: "1039386012609",
  appId: "1:1039386012609:web:b5eb1077af7c90371c1d40",
  measurementId: "G-559042METY"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);