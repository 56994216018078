import React from "react";

const DevelopmentComponent = (props) => {
    return(
        <>
        <div className='flex flex-col pb-4 justify-between border-b border-gray dev-card w-[calc(33.333%-13.333px)]
        lg:w-[calc(50%-10px)] lg:min-h-fit xs:w-full'>
            <div>
                <img src={props.img} alt=''/>
                <h1 className='text-gray font-[Roboto] mt-4 card-title'>{props.title}</h1>
            </div>
            {/* <div className='flex justify-between items-center'>
                <a href={props.link} className='text-lg font-semibold text-gray my-4 block
                lg:my-[14px] lg:text-white lg:text-base'>Читать далее</a>
                <img id='arr1' src={arrowWhiteRight} className='hidden lg:flex'/>
                <img id='arr2' src={arrowGreenRight} className='hidden'/>
            </div> */}
        </div>
        </>
    )
}

export default DevelopmentComponent;