import React, { useEffect } from "react";
import Header from "../components/Header";
import TechnologyCard from "../components/TechnologyCard";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SupportChat from "../components/SupportChat";

const ARB = () => {

    const { hash } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if(hash){
            const element = document.getElementById(hash.substring(1));
            if(element){
                element.scrollIntoView({});
            }
        }else{
            window.scrollTo({ top: 0, });
        }
    }, [hash]);

    return(
        <>
            <SupportChat />
            <Header />
            <div id='about' className='bg-bg-black py-40
            lg:pt-30 lg:pb-20
            xs:py-[50px]'>
                <div className='w-2/3 max-w-[1300px] mx-auto
                xxl:w-4/5
                xl:w-11/12'>
                    <h1 className='text-[75px] text-white font-medium
                    lg:text-[66px]
                    xs:text-[38px]'>Com-Unity ARB</h1>
                </div>
                <img alt='' src='images/arb_bg.webp' className='py-18 lg:py-[50px] xs:py-10'/>
                <div className='w-2/3 mx-auto
                xxl:w-4/5
                xl:w-11/12'>
                    <h1 className='text-[56px] text-white font-medium
                    lg:text-5xl
                    xs:text-[26px]'>{t('aboutApp')}</h1>
                    <p className='font-[Roboto] text-gray mt-10 w-3/5 
                    lg:w-4/6 lg:text-sm 
                    sm:w-4/5
                    xs:text-xs xs:w-full xs:mt-[18px] mb-6'>
                    </p>
                    {/* <span className='text-sm font-bold text-red-800 sm:text-xs' >{t('indev')}</span> */}
                    {/* <a className='block text-lg text-white mt-10 font-medium pb-3 border-b border-hr w-fit lg:text-base xs:mt-3 xs:text-xs xs:pb-2'>Читать далее</a> */}
                    <div className='mt-18 flex flex-col gap-y-5
                    lg:mt-[50px] 
                    xs:mt-[30px]'>
                        <TechnologyCard 
                            title={t('arbHeader1')}
                            subtitle={t('arbText1')}
                            reverse={true}
                            img='/images/arb1.svg'
                        />
                        <TechnologyCard 
                            title={t('arbHeader2')}
                            subtitle={t('arbText2')}
                            reverse={false}
                            img='/images/arb2.svg'
                        />
                        <TechnologyCard 
                            title={t('arbHeader3')}
                            subtitle={t('arbText3')}
                            reverse={true}
                            img='/images/tcard3.svg'
                        />
                        <TechnologyCard 
                            title={t('arbHeader4')}
                            subtitle={t('arbText4')}
                            reverse={false}
                            img='/images/tcard2.svg'
                        />
                        <TechnologyCard 
                            title={t('arbHeader5')}
                            subtitle={t('arbText5')}
                            reverse={true}
                            img='/images/tcard1.svg'
                        />
                        <TechnologyCard 
                            title={t('arbHeader6')}
                            subtitle={t('arbText6')}
                            reverse={false}
                            img='/images/arb3.svg'
                        />
                        <div className={`w-full flex justify-between py-30 px-28 border border-hr rounded-[20px] gap-x-10 
                        lg:py-[60px] lg:px-5
                        sm:flex-col`}>
                            <img alt='' src='/images/arb4.svg' className='w-2/5 lg:w-1/3 lg:mx-auto sm:w-2/3'/>
                            <div className='w-1/2 flex flex-col justify-center sm:w-full sm:mt-[34px] sm:items-center'>
                                <h1 className='font-medium text-white text-[38px] leading-tight
                                lg:text-[32px] 
                                sm:text-[21px] sm:text-center'>{t('arbHeader7')}</h1>
                                <p className='font-[Roboto] text-xl text-gray mt-8
                                lg:text-base lg:mt-[26px] sm:mt-[20px]
                                sm:text-sm sm:text-center' dangerouslySetInnerHTML={{ __html: t('arbText7') }}></p>
                            </div>
                        </div>
                        <TechnologyCard 
                            title={t('arbHeader8')}
                            subtitle={t('arbText8')}
                            reverse={false}
                            img='/images/arb5.svg'
                        />
                    </div>
                    
                
                </div>
            </div>
            <div id='arbitrage' className='py-40 bg-dark-green
            lg:py-20
            xs:py-[50px]'>
                <div className='w-2/3 max-w-[1300px] mx-auto
                xxl:w-4/5
                xl:w-11/12'>
                    <div className='flex sm:flex-col'>
                        <h1 className='w-1/2 text-white font-medium text-[38px]
                        lg:text-[32px]
                        xs:text-[26px] xs:w-full
                        '>{t('arbitrage')}</h1>
                        <p id='mission' className='w-1/2 font-[Roboto] text-xl text-gray
                        lg:text-base
                        sm:w-full sm:mt-10
                        xs:text-sm xs:w-full xs:mt-[18px]'>
                            {t('arbitragep1')}
                            <br></br><br></br>
                            {t('arbitragep2')}
                            </p>
                    </div>
                    <hr className='border-hr my-18 lg:my-[50px] xs:my-[30px]'></hr>
                    <div className='flex 
                    sm:flex-col
                    xs:flex-col'>
                        <h1 className='w-1/2 text-white font-medium text-[38px]
                        lg:text-[32px]
                        xs:text-[26px] xs:w-full'>{t('mission')}</h1>
                        <p className='w-1/2 font-[Roboto] text-xl text-gray
                        lg:text-base
                        sm:w-full sm:mt-10
                        xs:text-sm xs:w-full xs:mt-[18px]'>
                            {t('missionp1')}
                            <br></br><br></br>
                            {t('missionp2')}
                            </p>
                    </div>
                </div>
            </div>
            {/* <div id='technology' className='py-40 bg-bg-black
            lg:py-20
            xs:py-[50px]'>
                <div className='w-2/3 max-w-[1300px] mx-auto
                xxl:w-4/5
                xl:w-11/12'>
                    <div className='flex items-center 
                    sm:flex-col sm:items-start
                    xs:flex-col xs:items-start'>
                        <h1 className='text-white text-[56px] font-medium w-1/2
                        lg:text-5xl
                        xs:text-[26px]'>{t('technology')}</h1>
                        <h2 className='text-gray w-1/2
                        lg:text-sm
                        sm:w-full sm:mt-8
                        xs:text-xs xs:w-full xs:mt-[18px]'>{t('technologyTitle')}</h2>
                    </div>
                    <div className='mt-18 flex flex-col gap-y-5
                    lg:mt-[50px] 
                    xs:mt-[30px]'>
                        <TechnologyCard 
                            title={t('technologyCard1Title')}
                            subtitle={t('technologyCard1Subtitle')}
                            reverse={false}
                            img='/images/tcard1.svg'
                        />
                        <TechnologyCard 
                            title={t('technologyCard2Title')}
                            subtitle={t('technologyCard2Subtitle')}
                            reverse={true}
                            img='/images/tcard2.svg'
                        />
                        <TechnologyCard 
                            title={t('technologyCard3Title')}
                            subtitle={t('technologyCard3Subtitle')}
                            reverse={false}
                            img='/images/tcard3.svg'
                        />
                        <TechnologyCard 
                            title={t('technologyCard4Title')}
                            subtitle={t('technologyCard4Subtitle')}
                            reverse={true}
                            img='/images/tcard4.svg'
                        />
                        <TechnologyCard 
                            title={t('technologyCard5Title')}
                            subtitle={t('technologyCard5Subtitle')}
                            reverse={false}
                            img='/images/tcard5.svg'
                        />
                    </div>
                </div>
            </div> */}
            <div id='invite' className='py-40 bg-dark-green
            lg:py-20
            xs:py-[50px]'>
                <div className='w-2/3 max-w-[1300px] mx-auto flex justify-between
                xxl:w-4/5
                xl:w-11/12
                sm:flex-col-reverse
                xs:flex-col-reverse'>
                    <div className='w-1/2 
                    sm:w-full
                    xs:w-full'>
                        <h1 className='text-white font-medium text-[56px]
                        lg:text-5xl
                        xs:text-[26px]'>{t('invite')}</h1>
                        <p className='mt-10 text-gray font-[Roboto] text-xl
                        lg:text-base
                        xs:text-xs xs:mt-[18px]'>
                            {t('invitep1')}
                            <br></br><br></br>
                            {t('invitep2')}
                            </p>
                    </div>
                    <img alt='' src='images/inv.png' className='w-1/2 
                    sm:mx-auto sm:w-full
                    xs:mx-auto xs:w-full'/>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ARB;