import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import token1 from "../images/token1.png";
import { useLocation } from "react-router-dom";
import PrimaryButton from "../components/PrimaryButton";
import Chart from "../components/Chart";
import { useTranslation } from 'react-i18next';
import SupportChat from "../components/SupportChat";
import TokenAboutComp from "../components/TokenAboutComp";

const Token = () => {

    const { hash } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if(hash){
            const element = document.getElementById(hash.substring(1));
            if(element){
                element.scrollIntoView({ });
            }
        }else{
            window.scrollTo({ top: 0, });
        }
    }, [hash]);

    return (
        <>
            <SupportChat />
            <Header />
            <div id='about' className='w-full bg-bg-black py-40
            lg:py-20
            xs:py-[50px]'>
                <div className='w-2/3 max-w-[1300px] mx-auto
                xxl:w-4/5
                xl:w-11/12'>
                    <div className='flex items-center justify-between 
                    sm:flex-col-reverse sm:items-start
                    xs:flex-col-reverse xs:items-start'>
                        <div className='w-1/2 mt-[35px]
                        sm:w-full
                        xs:w-full'>
                            <h1 className='text-[56px] text-white font-medium leading-none
                            lg:text-5xl
                            xs:text-[26px]'>{t('aboutTokenHeader')}</h1>
                            <p className='mt-10 font-[Roboto] text-gray
                            xs:text-sm xs:mt-[18px] mb-6'>
                                {t('aboutTokenp1')}
                            </p>
                            <span className='text-sm font-bold text-red-800 sm:text-xs'>{t('indev')}</span>
                        </div>
                        <img alt='' src={token1} className='w-1/2 
                        sm:w-full sm:mx-auto
                        xs:w-full'/>
                    </div>
                    <hr className='border-hr mt-[65px] pb-[70px] 
                    lg:pb-[50px] lg:mt-[50px] 
                    xs:pb-10 xs:mt-10'></hr>
                    <div className='text-[38px] font-exo font-medium text-white w-11/12 leading-tight
                    lg:text-[21px] lg:font-normal 
                    sm:w-full
                    xs:w-full'>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex items-center sm:flex-col-reverse">
                            <div className="w-1/2 sm:w-full sm:mt-8">
                                <p className='text-green text-2xl'>{t('aboutTokenCompHead1')}</p>
                                <p className="text-base font-[Roboto] text-gray xs:text-sm" dangerouslySetInnerHTML={{__html: t('aboutTokenCompText1')}}></p>
                            </div>
                            <img className='w-1/3 mx-auto sm:w-2/3' src='./images/token2.svg' alt="" />
                        </div>
                        <hr className='border-hr mt-[65px] pb-[70px] lg:pb-[50px] lg:mt-[50px] xs:pb-10 xs:mt-10'></hr>
                        <div className="flex items-center sm:flex-col">
                            <img className='w-1/3 mx-auto sm:w-2/3' src='./images/token1.svg' alt="" />
                            <div className="w-1/2 sm:w-full sm:mt-8">
                                <p className='text-green text-2xl'>{t('aboutTokenCompHead2')}</p>
                                <p className="text-base font-[Roboto] text-gray xs:text-sm" dangerouslySetInnerHTML={{__html: t('aboutTokenCompText2')}}></p>
                            </div>
                        </div>
                        <hr className='border-hr mt-[65px] pb-[70px] lg:pb-[50px] lg:mt-[50px] xs:pb-10 xs:mt-10'></hr>
                        <div className="flex items-center sm:flex-col-reverse">
                            <div className="w-1/2 sm:w-full sm:mt-8">
                                <p className='text-green text-2xl'>{t('aboutTokenCompHead3')}</p>
                                <p className="text-base font-[Roboto] text-gray xs:text-sm" dangerouslySetInnerHTML={{__html: t('aboutTokenCompText3')}}></p>
                            </div>
                            <img className='w-1/3 mx-auto sm:w-2/3' src='./images/token3.svg' alt="" />
                        </div>
                        <hr className='border-hr mt-[65px] pb-[70px] lg:pb-[50px] lg:mt-[50px] xs:pb-10 xs:mt-10'></hr>
                        <div className="flex items-center sm:flex-col">
                            <img className='w-1/3 mx-auto sm:w-2/3' src='./images/token4.svg' alt="" />
                            <div className="w-1/2 sm:w-full sm:mt-8">
                                <p className='text-green text-2xl'>{t('aboutTokenCompHead4')}</p>
                                <p className="text-base font-[Roboto] text-gray xs:text-sm" dangerouslySetInnerHTML={{__html: t('aboutTokenCompText4')}}></p>
                            </div>
                        </div>
                        <hr className='border-hr mt-[65px] pb-[70px] lg:pb-[50px] lg:mt-[50px] xs:pb-10 xs:mt-10'></hr>
                        <div className="flex items-center sm:flex-col-reverse">
                            <div className="w-1/2 sm:w-full sm:mt-8">
                                <p className='text-green text-2xl'>{t('aboutTokenCompHead5')}</p>
                                <p className="text-base font-[Roboto] text-gray xs:text-sm" dangerouslySetInnerHTML={{__html: t('aboutTokenCompText5')}}></p>
                            </div>
                            <img className='w-1/3 mx-auto sm:w-2/3' src='./images/token5.svg' alt="" />
                        </div>
                        <hr className='border-hr mt-[65px] pb-[70px] lg:pb-[50px] lg:mt-[50px] xs:pb-10 xs:mt-10'></hr>
                        <div className="flex items-center sm:flex-col">
                            <img className='w-1/3 mx-auto sm:w-2/3' src='./images/token6.svg' alt="" />
                            <div className="w-1/2 sm:w-full sm:mt-8">
                                <p className='text-green text-2xl'>{t('aboutTokenCompHead6')}</p>
                                <p className="text-base font-[Roboto] text-gray xs:text-sm" dangerouslySetInnerHTML={{__html: t('aboutTokenCompText6')}}></p>
                            </div>
                        </div>
                        <hr className='border-hr mt-[65px] pb-[70px] lg:pb-[50px] lg:mt-[50px] xs:pb-10 xs:mt-10'></hr>
                        <div className="flex items-center sm:flex-col-reverse">
                            <div className="w-1/2 sm:w-full sm:mt-8">
                                <p className='text-green text-2xl'>{t('aboutTokenCompHead7')}</p>
                                <p className="text-base font-[Roboto] text-gray xs:text-sm" dangerouslySetInnerHTML={{__html: t('aboutTokenCompText7')}}></p>
                            </div>
                            <img className='w-1/3 mx-auto sm:w-2/3' src='./images/summary.png' alt="" />
                        </div>
                        <hr className='border-hr mt-[65px] lg:mt-[50px] xs:mt-10'></hr>
                    </div>
                </div>
            </div>
            <div id='buy' className='w-full bg-[url(images/token_bg.webp)] h-[597px] bg-no-repeat bg-cover
            4k:bg-[center_-200px]

            lg:h-[410px] lg:bg-[url(images/token_bg2.webp)]
            md:bg-right
            sm:bg-[url(images/tok-mob.webp)] sm:h-[700px] sm:bg-center
            xs:bg-[url(images/tok-mob.webp)] xs:h-[550px] xs:bg-center'>
                <div className='w-2/3 max-w-[1300px] mx-auto flex justify-end pt-40
                xxl:w-4/5
                xl:w-11/12
                lg:pt-20 lg:justify-start
                md:pt-14
                xs:py-[50px]'>
                    <div>
                        <h1 className='text-[56px] text-white font-medium 
                        lg:text-5xl
                        md:w-1/2 md:leading-tight
                        sm:text-[32px] sm:w-full
                        xs:text-[26px]'>{t('buyToken')}</h1>
                        <h2 className='font-[Roboto] text-gray text-xl mt-6 mb-6 sm:mb-2
                        lg:text-xl lg:mt-[26px]
                        md:w-2/3
                        md:mt-4
                        sm:mt-5 sm:w-2/3
                        xs:text-sm xs:w-4/5'>{t('aboutTokenAndCom')}</h2>
                        <span className='text-sm font-bold text-red-800 sm:text-xs'>{t('notbuy')}</span>
                        {/* <PrimaryButton text={t('btnBuy')}/> */}
                    </div>
                </div>
            </div>
            <div id='etherscan' className='bg-bg-black py-40
            lg:py-20
            xs:py-[50px]'>
                <div className='w-2/3 max-w-[1300px] mx-auto
                xxl:w-4/5
                xl:w-11/12'>
                    <h1 className='text-[56px] text-white font-medium w-1/2 leading-none mb-6 sm:mb-2
                    lg:text-5xl lg:w-2/3
                    sm:w-full
                    xs:text-[26px] xs:w-4/5 xs:leading-tight'>{t('spec')} <span className='text-green'>EtherScan</span></h1>
                    {/* <img alt='' src={table} className='mt-18 
                    lg:mt-[50px]
                    xs:mt-[30px]'/> */}
                    {/* <Chart /> */}
                    <p className='text-sm font-bold text-red-800 sm:text-xs leading-none sm:w-4/5'>{t('activity')}</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Token;