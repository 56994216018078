import React from "react";
import SecondButton from "./SecondButton";
import { Link } from "react-router-dom";

const ProjectComponent = (props) => {
    return(
        <div className='bg-bg-black rounded-3xl flex lg:min-h-[362px] xs:hidden'>
            <div className='py-12 pl-12 flex flex-col justify-between w-1/2
                lg:pt-5
                lg:pl-5
                lg:pb-[30px]'>
                {/* <span className='font-[Roboto] text-xl text-green'>{props.number}</span> */}
                <div>
                    <h1 className='text-[38px] text-white font-medium
                        lg:text-[32px]'>{props.title}</h1>
                    <h2 className='text-xl text-gray font-[Roboto] mt-[34px] mb-[38px] w-4/5
                        lg:text-base
                        lg:w-full
                        lg:mt-[26px] 
                        lg:mb-7'>{props.subtitle}</h2>
                    {props.btn ? <Link to={props.link}><SecondButton text={props.btn} /></Link> : ''}
                </div>
            </div>
            <img alt='' src={props.img} className={`mx-auto lg:w-2/5 lg:h-fit lg:mx-auto lg:my-auto ${props.width}`}/>
        </div>
    )
}

export default ProjectComponent;