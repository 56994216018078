import React from "react";
import arrow from "../images/arrow_green_right.svg";

const SecondButton = (props) => {
    return(
        <button className='relative group text-lg text-white font-semibold px-8 py-5 border-hr border rounded-[60px] font-[Inter] hover:text-green flex items-center
        lg:py-[14px]
        lg:px-6
        lg:text-base'>
            {props.text}
            <img alt='' src={arrow} className='w-0 group-hover:w-fit group-hover:ml-3'/>
        </button>
    )
}

export default SecondButton;