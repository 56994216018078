import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import PrimaryButton from "../components/PrimaryButton";
import SupportChat from "../components/SupportChat";
import PlanComponent from "../components/PlanComponent";
import Footer from "../components/Footer";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Wallet = () => {

    const { hash } = useLocation();
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if(hash){
            const element = document.getElementById(hash.substring(1));
            if(element){
                element.scrollIntoView({});
            }
        }else{
            window.scrollTo({ top: 0, });
        }
    }, [hash]);

    return(
        <>
            <div onClick={()=> setModal(false)} className={`w-full h-[100vh] bg-bg-black z-20 bg-opacity-60 flex justify-center items-center ${modal ? 'fixed' : 'hidden'}`}>
                <div className='bg-bg-black p-15 flex justify-between sm:flex-col sm:p-10 sm:w-10/12'>
                    <div className='w-1/2 flex flex-col justify-between sm:w-full sm:items-center'>
                        <h1 className="text-white text-3xl font-exo leading-none sm:text-center">{t('worldWallet')}</h1>
                        <img src="images/playmarket.svg" className="sm:mt-4" width={200}/>
                    </div>
                    <img src='images/qr.svg' className="sm:w-1/2 sm:mt-4 sm:mx-auto"/>

                </div>
            </div>
            <SupportChat />
            <Header />
            <div id='about' className='w-full h-[100vh] bg-[url(images/wallet_bg.webp)] bg-cover bg-top
            sm:bg-[url(images/wallet_mob.webp)] sm:bg-bottom'>
                <div className='w-2/3 mx-auto pt-[275px] max-w-[1300px] flex
                    xxl:w-4/5
                    xl:w-11/12
                    lg:pt-[180px]
                    xs:pt-[130px]'>
                    <div className='w-1/2 
                        sm:w-4/5
                        xs:w-full'>
                        <h1 className='text-white text-[75px] font-medium leading-light
                                lg:text-[66px]
                                md:text-[56px]
                                xs:text-[38px]'>{t('aboutWallet')}</h1>
                        <h2 className='text-[20px] text-gray font-[Roboto] leading-snug mt-[34px] w-5/6
                                lg:text-sm lg:mt-[26px] lg:w-10/12
                                xs:mt-[22px] xs:w-11/12 sm:mb-8'>{t('aboutWalletSub')}</h2>
                        <div className="mt-10">
                            <PrimaryButton text={t('downloadWallet')} href="/file/file.pdf" download={true}/>
                        </div>
                    </div>
                </div>
            </div>

            <div id='advantages' className='bg-bg-black'>
                <div className='w-2/3 mx-auto max-w-[1300px] py-40 flex flex-wrap gap-x-5 gap-y-7
                xxl:w-4/5
                xl:w-11/12 lg:py-20
                xs:py-[50px] xs:flex-col'>
                    <h1 className='text-[56px] text-white leading-none font-medium mt-7 w-full pb-16
                        lg:text-5xl
                        sm:pb-10
                        xs:mt-0 xs:text-[26px]
                        '>{t('advantages')}</h1>
                        <PlanComponent 
                            img='/images/w1.svg'
                            title={t('w1Title')}
                            subtitle={t('w1Subtitle')}
                        />
                        <PlanComponent 
                            img='/images/w2.svg'
                            title={t('w2Title')}
                            subtitle={t('w2Subtitle')}
                        />
                        <PlanComponent 
                            img='/images/w3.svg'
                            title={t('w3Title')}
                            subtitle={t('w3Subtitle')}
                        />
                        <PlanComponent 
                            img='/images/w4.svg'
                            title={t('w4Title')}
                            subtitle={t('w4Subtitle')}
                        />
                        <PlanComponent 
                            img='/images/w5.svg'
                            title={t('w5Title')}
                            subtitle={t('w5Subtitle')}
                        />
                        <PlanComponent 
                            img='/images/w6.svg'
                            title={t('w6Title')}
                            subtitle={t('w6Subtitle')}
                        />
                    </div>
            </div>

            <div id='join' className='w-full bg-dark-green'>
                <div className='w-2/3 max-w-[1300px] mx-auto flex justify-end py-40
                xxl:w-4/5
                xl:w-11/12
                lg:py-20
                md:flex-col-reverse
                xs:py-[50px]'>
                    <img src="images/wallet_join.svg" alt=""  className="mr-20 w-2/5 md:mx-auto md:w-1/2 md:mt-10 sm:w-3/4"/>
                    <div className="flex flex-col justify-center items-start">
                        <h1 className='text-[56px] text-white font-medium leading-none
                        lg:text-5xl
                        md:w-full md:leading-tight
                        sm:text-[32px] sm:w-full
                        xs:text-[26px]'>{t('wallet_join')}</h1>
                        <h2 className='font-[Roboto] text-gray mt-6 sm:mb-2
                        lg:mt-[26px]
                        md:w-2/3
                        md:mt-4
                        sm:mt-5 sm:w-2/3
                        xs:text-sm xs:w-4/5'>{t('wallet_join_sub')}</h2>
                        <div className="mt-10">
                            <PrimaryButton text={t('downloadWallet')} href="/file/file.pdf" download={true}/>
                        </div>
                    </div>
                </div>
            </div>

            <div id='download' className='w-full bg-bg-black'>
                <div className='w-2/3 max-w-[1300px] mx-auto flex justify-end py-40
                xxl:w-4/5
                xl:w-11/12
                xl:py-20
                md:flex-col
                xs:py-[50px]'>
                    <div className="flex flex-col justify-center items-start">
                        <h1 className='text-[56px] text-white font-medium 
                        lg:text-5xl
                        md:w-full md:leading-tight
                        sm:text-[32px] sm:w-full
                        xs:text-[26px]'>{t('downloadWallet')}</h1>
                        <h2 className='font-[Roboto] text-gray mt-6 sm:mb-2 w-4/5
                        lg:mt-[26px]
                        md:w-2/3
                        md:mt-4
                        sm:mt-5 sm:w-2/3
                        xs:text-sm xs:w-4/5'>{t('download_sub')}</h2>
                        <div className="mt-10">
                            <PrimaryButton text={t('downloadWallet')} href="/file/file.pdf" download={true}/>
                        </div>
                    </div>
                    <img src="images/wallet_bg2.png" alt=""  className="w-1/2 md:mx-auto md:mt-10 sm:w-3/4"/>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Wallet;