import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SupportChat from "../components/SupportChat";

const Main = () => {

    const { hash } = useLocation();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(hash){
            const element = document.getElementById(hash.substring(1));
            if(element){
                element.scrollIntoView({ });
            }
        }else{
            window.scrollTo({ top: 0, });
        }
    }, [hash]);

    return(
        <>
            <SupportChat />
            <Header />
            <div className='w-full h-[100vh]'>
                {
                    window.innerWidth < 1200 && window.innerWidth >= 768 ?
                    <video className='w-full h-[100vh] object-cover absolute' src="video/Main-lg.mp4" type="video/mp4" autoPlay playsInline muted controls={false}/>
                    : window.innerWidth < 768 ?
                    <video className='w-full h-[100vh] object-cover absolute' src="video/Mobile.mp4" type="video/mp4" autoPlay playsInline muted controls={false}/>
                    : <video className='w-full h-[100vh] object-cover absolute' src="video/Main.mp4" type="video/mp4" autoPlay playsInline muted controls={false}/>
                }
                <div className='relative w-2/3 mx-auto pt-[275px] max-w-[1300px] flex
                xxl:w-4/5
                xl:w-11/12
                lg:pt-[180px]
                xs:pt-[130px]'>
                    <div className='w-1/2 
                    sm:w-4/5
                    xs:w-full'>
                        <h1 className='text-white text-[75px] font-medium leading-light
                        lg:text-[66px]
                        xs:text-[38px]'>{t('mainTitle')}</h1>
                        <h3 className='text-[20px] text-gray font-[Roboto] leading-snug mt-[34px]
                        lg:text-sm lg:mt-[26px] lg:w-10/12
                        xs:mt-[22px] xs:w-11/12'>{t('mainSubTitle')}</h3>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Main;