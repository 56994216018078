import React from "react";

const TechnologyCard = (props) => {
    return(
        <>
            <div className={`w-full flex justify-between py-30 px-28 border border-hr rounded-[20px] gap-x-10 
            lg:py-[60px] lg:px-5
            sm:flex-col
            ${props.reverse ? 'flex-row-reverse' : ''}`}>
                <img alt='' src={props.img} className='w-2/5 lg:w-1/3 lg:mx-auto sm:w-2/3'/>
                <div className='w-1/2 flex flex-col justify-center sm:w-full sm:mt-[34px] sm:items-center'>
                    <h1 className='font-medium text-white text-[38px] leading-tight
                    lg:text-[32px] 
                    sm:text-[21px] sm:text-center'>{props.title}</h1>
                    <p className='font-[Roboto] text-xl text-gray mt-8
                    lg:text-base lg:mt-[26px] sm:mt-[20px]
                    sm:text-sm sm:text-center'>{props.subtitle}</p>
                </div>
            </div>
        </>
    )
}

export default TechnologyCard;