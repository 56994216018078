import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PrimaryButton from "../components/PrimaryButton";

const NotFound = () => {
    return(
        <>
            <Header />
            <div className='w-full bg-bg-black rounded-b-[50px] mb-[2px] lg:rounded-b-[18px]'>
                <div className='w-2/3 max-w-[1300px] mx-auto pb-30 pt-[160px] flex items-center
                xxl:w-4/5
                xl:w-11/12
                lg:justify-between
                lg:pb-16 lg:pt-[100px]
                xs:flex-col-reverse'>
                    <div className='w-1/2
                    lg:w-2/5
                    xs:w-full xs:flex xs:flex-col
                    xs:items-center xs:text-center'>
                        <span className='text-light-green bg-dark-green font-medium px-4 py-2 rounded-[60px]
                        xs:text-xs xs:px-[14px] xs:py-[4px]'>404</span>
                        <h1 className='text-white text-[75px] font-medium leading-none mt-5
                        lg:text-[66px] lg:mt-4
                        xs:text-[38px] xs:mt-[10px] xs:w-3/5'>Страница не найдена</h1>
                        <h2 className='mt-8 font-[Roboto] text-xl text-gray mb-10
                        lg:text-sm lg:mt-[26px] lg:mb-[30px]
                        xs:mt-[22px] xs:mb-[26px] xs:w-4/5'>Неправильно набран адрес или такой страницы на сайте больше не существует</h2>
                        <PrimaryButton text='Перейти на главную' href='/'/>
                    </div>
                    <img alt='' src='/images/404.png' className='w-1/2
                    xs:w-full'/>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default NotFound;