import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DevelopmentComponent from "../components/DevelopmentComponent";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SupportChat from "../components/SupportChat";


const News = () => {

    const { hash } = useLocation();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(hash){
            const element = document.getElementById(hash.substring(1));
            if(element){
                element.scrollIntoView({ });
            }
        }else{
            window.scrollTo({ top: 0, });
        }
    }, [hash]);

    return (
        <>
        <SupportChat />
        <Header />
        <div id='development' className='bg-bg-black'>
            <div className='w-2/3 max-w-[1300px] mx-auto py-40 
            xxl:w-4/5
            xl:w-11/12
            lg:pb-20 lg:pt-30
            xs:py-[50px]'>
                <h1 className='text-[56px] text-white font-exo font-medium
                lg:text-5xl
                xs:text-[26px]'>{t('development')}</h1>
                <div className='mt-[70px] flex flex-wrap gap-y-20 gap-x-5
                lg:mt-[50px]
                xs:mt-[30px]
                xs:gap-y-[30px]'>
                    <DevelopmentComponent 
                    img='/images/dev1.png'
                    title={t('developmentComp1Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/dev2.png'
                    title={t('developmentComp2Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/dev3.png'
                    title={t('developmentComp3Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/dev4.png'
                    title={t('developmentComp4Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/dev5.png'
                    title={t('developmentComp5Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/dev6.png'
                    title={t('developmentComp6Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/dev7.png'
                    title={t('developmentComp7Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/dev8.png'
                    title={t('developmentComp8Title')}
                    link=''/>
                </div>
            </div>
        </div>
        <div id='partners' className='bg-dark-green'>
            <div className='w-2/3 max-w-[1300px] mx-auto py-40 
            xxl:w-4/5
            xl:w-11/12
            lg:py-20
            xs:py-[50px]'>
                <h1 className='text-[56px] text-white font-exo font-medium 
                lg:text-5xl
                xs:text-[26px]'>{t('partners')}</h1>
                <div className='mt-[70px] flex flex-wrap gap-y-20 gap-x-5 
                xs:mt-[30px]
                xs:gap-y-[30px]'>
                    <DevelopmentComponent 
                    img='/images/parther1.png'
                    title={t('pathersComp1Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/parther2.png'
                    title={t('pathersComp2Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/parther3.png'
                    title={t('pathersComp3Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/parther4.png'
                    title={t('pathersComp4Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/parther5.png'
                    title={t('pathersComp5Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/parther6.png'
                    title={t('pathersComp6Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/parther7.png'
                    title={t('pathersComp7Title')}
                    link=''/>
                    <DevelopmentComponent 
                    img='/images/parther8.png'
                    title={t('pathersComp8Title')}
                    link=''/>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default News;